import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/elements/Spinner/Spinner";
import {
    REACT_APP_BACKEND_URL
} from "../../../config/config";
const BulkExport = () => {
    const [jobId, setJobId] = useState(null);
    const [progress, setProgress] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [isRunning, setIsRunning] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [copied, setCopied] = useState(false);
    const startExport = async () => {
        setDownloadUrl([])
        setProgress(true)
        setIsRunning(true)
        try {
            const res = await fetch(`${REACT_APP_BACKEND_URL}/bulk-export`, {
                method: 'GET', // Change if needed
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await res.json();
            setJobId(data?.jobId["content-location"]);
            setProgress(false)
        } catch (error) {
            setProgress(false)
            console.error('Error starting export:', error);
            toast.error(error.message || 'Failed to start export');
        }
    };

    useEffect(() => {
        if (!isRunning || !jobId) return;
        setProgressPercentage(10)
        const fetchStatus = async () => {
            try {
                const res = await fetch(`${REACT_APP_BACKEND_URL}/bulk-export-status/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ url: jobId }),
                });
                const data = await res.json();
                if (data.export_status) {
                    setDownloadUrl(data.export_status);
                    setIsRunning(0)
                    setTimeout(() => setProgressPercentage(0), 500);
                    setProgressPercentage(100); // Set progress to 100% when complete
                } else {
                    setProgressPercentage((prev) => Math.min(prev + 15, 95)); // Simulate progress increase
                }
            } catch (error) {
                console.error("Error checking status:", error);
                toast.error("Error checking status");
            } finally {
                setProgress(false);
            }
        };

        const interval = setInterval(fetchStatus, 2000); // Poll every 2 seconds

        return () => clearInterval(interval);
    }, [isRunning, jobId]);

    const downloadFile = async (url,type) => {
        setProgress(true)
        try {
            const res = await fetch(`${REACT_APP_BACKEND_URL}/download-bulk-export`, {
                method: "POST", // Use "POST" or "PUT" depending on your API
                headers: {
                    "Content-Type": "application/json", // Set the content type
                },
                body: JSON.stringify({ url: url }), // Convert payload to JSON string
            });
            const data = await res.json();

            if (data?.export_json) {
                // Convert JSON to Blob
                const blob = new Blob([data.export_json], { type: "application/json" });

                // Create a download link
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = `${type}_${url?.split("/").pop()}.ndjson`; // Name of the downloaded file
                // Trigger the download
                document.body.appendChild(link);
                link.click();
                // Cleanup
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
                setProgress(false)

            } else {
                console.error("No data received for download.");
                setProgress(false)
            }
        } catch (error) {
            console.log(error);
            setProgress(false)
            toast.error('Error checking status');
        }
    }

    const handleCopy = async (x) => {
        await navigator.clipboard.writeText(x.url);
        toast.success('Copied!');
    };
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-lg text-center">
                <h1 className="text-2xl font-bold text-gray-800 mb-6">Epic Bulk Export</h1>
                <button
                    onClick={startExport}
                    disabled={isRunning}
                    className="btn btn-success"
                >
                    {isRunning ? "Exporting..." : "Start Export"}
                </button>
                {progressPercentage > 0 && (
                    <div className="progress mt-3">
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: `${progressPercentage}%` }}
                        >
                            {progressPercentage}%
                        </div>
                    </div>
                )}

                <div className="white-box">
                    <div className="table-container">
                        <table className="styled-table">
                            <thead >
                                <tr >
                                    <th >Resource</th>
                                    <th >URL</th>
                                    <th >Action</th>
                                </tr>
                            </thead>
                            <tbody className="text-start">
                                {downloadUrl?.length > 0 ? (
                                    downloadUrl?.map((x, index) => (
                                        <tr key={index} >
                                            <td className="">{x?.type}</td>
                                            <td className="">{truncateMiddle(x?.url)}
                                                {/* <button onClick={() => handleCopy(x?.url)} data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={"Click to copy"} className=" btn btn-outline-primary btn-sm ms-3">
                                                    <i className={"fas fa-copy"}></i>
                                                </button> */}
                                            </td>
                                            <td className="" >
                                                <a
                                                    onClick={() => downloadFile(x?.url,x?.type)}
                                                    className="view-button"
                                                >
                                                    Download
                                                </a>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="p-4 text-center text-gray-500">No resources available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Spinner isLoading={progress} />

        </div>
    );
};

export default BulkExport;
const truncateMiddle = (str, maxLength = 60) => {
    if (!str || str.length <= maxLength) return str;
    const startLength = Math.floor(maxLength / 2) - 5; // Start part
    const endLength = Math.ceil(maxLength / 2) - 5; // End part
    return `${str.slice(0, startLength)}...${str.slice(-endLength)}`;
};
