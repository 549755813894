import { Navigate, Route, Routes } from "react-router-dom";

import ProtectedRoutes from "./components/routes/ProtectedRoutes";
import { ROLES } from "./config/roles";
import Login from "./pages/auth/Login";
import VerifyOTP from "./pages/auth/VerifyOTP";
import Register from "./pages/auth/Register";
import TermAndCondition from "./pages/public/TermAndCondition";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";
import ResetPassword from "./pages/auth/ResetPassword";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminChangePassword from "./pages/Admin/AdminChangePassword";
import AdminOrganization from "./pages/Admin/AdminOrganization";
import AdminUserManagement from "./pages/Admin/AdminUserManagement";
import AdminOrganizationManagement from "./pages/Admin/AdminOrganizationManagement";
import MasterUserDashboard from "./pages/MasterUser/MasterUserDashboard";
import MasterUserUserManagement from "./pages/MasterUser/MasterUserUserManagement";
import MasterUserChangePassword from "./pages/MasterUser/MasterUserChangePassword";
import UserDashboard from "./pages/User/UserDashboard";
import UserChangePassword from "./pages/User/UserChangePassword";
import ActivateUser from "./pages/auth/ActivateUser";
import Error from "./pages/Error";
import MasterCustomerManagement from "./pages/MasterUser/MasterUserCustomerManagement";
import AdminCustomerManagement from "./pages/Admin/AdminCustomerManagement";
import AdminCustomerSubscription from "./pages/Admin/CustomerSubscription/AdminCustomerSubscription";
import MasterUserProfile from "./pages/MasterUser/MasterUserProfile";
import MasterUserCustomerSubscription from "./pages/MasterUser/Subscriptions/MasterUserCustomerSubscription";
import UserProfile from "./pages/User/UserProfile";
import UserCustomerSubscription from "./pages/User/Subscriptions/UserCustomerSubscription";
import UserCustomerManagement from "./pages/User/UserCustomerManagement";
import MasterUserNotificationManagement from "./pages/MasterUser/MasterUserNotificationManagement";
import AdminNotificationManagement from "./pages/Admin/AdminNotificationManagement";
import MasterUserNotificationList from "./pages/MasterUser/MasteruserNotificationList";
import AdminNotificationList from "./pages/Admin/AdminNotificationList";
import UserNotificationList from "./pages/User/UserNotificationList";
import AdminOrganizationNotificationManagement from "./pages/Admin/AdminOrganizationNotificationManagement";
import UserScheduler from "./pages/User/UserScheduler";
import MasterUserScheduler from "./pages/MasterUser/MasterUserScheduler";
import ExploreFhirPatients from "./pages/public/FHIR/ExploreFhirPatients";
import ExploreFhirPatientDetails from "./pages/public/FHIR/ExploreFhirPatientDetails";
import BulkExport from "./pages/public/FHIR/BulkExport";

export const BaseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify-code" element={<VerifyOTP />} />
      <Route path="/register" element={<Register />} />
      <Route path="/user/activate" element={<ActivateUser />} />
      <Route path="/error" element={<Error />} />
      <Route path="/terms-and-conditions" element={<TermAndCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/explore-fhir/patients" element={<ExploreFhirPatients />} />
      <Route path="/explore-fhir/bulk-export" element={<BulkExport />} />
      <Route
        path="/explore-fhir/patients/:patientId"
        element={<ExploreFhirPatientDetails />}
      />

      {/* Protected Route for User */}
      <Route element={<ProtectedRoutes allowedRole={ROLES.USER} />}>
        <Route path="/user/home" element={<UserDashboard />} />
        <Route
          path="/user/notifications-list"
          element={<UserNotificationList />}
        />
        <Route path="/user/customers" element={<UserCustomerManagement />} />
        <Route path="/user/scheduler" element={<UserScheduler />} />
        <Route
          path="/user/customers/subscription"
          element={<UserCustomerSubscription />}
        />
        <Route path="/user/change-password" element={<UserChangePassword />} />
        <Route path="/user/profile" element={<UserProfile />} />
      </Route>

      {/* Protected Route for Master User */}
      <Route element={<ProtectedRoutes allowedRole={ROLES.MASTERUSER} />}>
        <Route path="/masteruser/home" element={<MasterUserDashboard />} />

        <Route
          path="/masteruser/customers"
          element={<MasterCustomerManagement />}
        />
        <Route path="/masteruser/scheduler" element={<MasterUserScheduler />} />
        <Route
          path="/masteruser/notification-management"
          element={<MasterUserNotificationManagement />}
        />
        <Route
          path="/masteruser/notifications-list"
          element={<MasterUserNotificationList />}
        />
        <Route
          path="/masteruser/customers/subscription"
          element={<MasterUserCustomerSubscription />}
        />
        <Route
          path="/masteruser/user-management"
          element={<MasterUserUserManagement />}
        />
        <Route
          path="/masteruser/change-password"
          element={<MasterUserChangePassword />}
        />
        <Route path="/masteruser/profile" element={<MasterUserProfile />} />
      </Route>

      {/* Protected Route for Admin */}
      <Route element={<ProtectedRoutes allowedRole={ROLES.ADMIN} />}>
        <Route path="/admin/home" element={<AdminDashboard />} />
        <Route path="/admin/profile" element={<AdminOrganization />} />
        <Route
          path="/admin/organization-management/:orgId/customers/subscription"
          element={<AdminCustomerSubscription />}
        />
        <Route
          path="/admin/organization-management/:orgId/customers"
          element={<AdminCustomerManagement />}
        />
        <Route
          path="/admin/organization-management/:orgId/notifications"
          element={<AdminOrganizationNotificationManagement />}
        />
        <Route
          path="/admin/organization-management"
          element={<AdminOrganizationManagement />}
        />
        <Route
          path="/admin/user-management"
          element={<AdminUserManagement />}
        />
        <Route
          path="/admin/notification-management"
          element={<AdminNotificationManagement />}
        />
        <Route
          path="/admin/notifications-list"
          element={<AdminNotificationList />}
        />
        <Route
          path="/admin/change-password"
          element={<AdminChangePassword />}
        />
      </Route>
      <Route path="/404" element={<Error />} />
      <Route path="*" element={<Navigate replace to={"/404"} />} />
    </Routes>
  );
};
