import React, { useEffect, useState } from "react";

import Select from "react-select";
import {
  useGetEHRSystemsQuery,
  // useGetPatientsQuery,
} from "../../../services/apiService";
import { useNavigate } from "react-router-dom";
const $ = window.$;

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.245rem 0.75rem",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const patients = [
  {
    name: "Camila Lopez",
    id: "erXuFYUfucBZaryVksYEcMg3",
    MRN: 203713
  },
  {
    name: "Derrick Lin",
    id: "eq081-VQEgP8drUUqCWzHfw3",
    MRN: 203711
  },
  {
    name: "Desiree Powell",
    id: "eAB3mDIBBcyUKviyzrxsnAw3",
    MRN: 203714
  },
  {
    name: "Elijah Davis",
    id: "egqBHVfQlt4Bw3XGXoxVxHg3",
    MRN: 203709
  },
  {
    name: "Olivia Roberts",
    id: "eh2xYHuzl9nkSFVvV3osUHg3",
    MRN: 203715
  },
  {
    name: "Linda Ross",
    id: "eIXesllypH3M9tAA5WdJftQ3",
    MRN: 203712
  },
  {
    name: "Warren McGinnis",
    id: "e0w0LEDCYtfckT6N.CkJKCw3",
    MRN: 203710
  },
  {
    name: "Raj ICU",
    id: "eovSKnwDlsv-8MsEzCJO3BA3",
  },
  {
    name: "Theodore MyChart",
    id: "e63wRTbPfr1p8UW81d8Seiw3",
  },
  {
    name: "Henry Clin Doc",
    id: "eXFljJT8WxVd2PjwvPAGR1A3",
  },
  {
    name: "Diego Ambulatory",
    id: "exXRmhbBlDmkQs7JHPE37Yw3",
  },
  {
    name: "Damon MyChart",
    id: "enh2Q1c0oNRtWzXArnG4tKw3",
  },
  {
    name: "Louie Beacon",
    id: "eBZnFnAwp8rVbEJP1yHg7rw3",
  },
  {
    name: "Jennifer Infection Control",
    id: "eTplvxRvcd-eT1nEI8BvQRQ3",
  },
  {
    name: "Eleanor Long Term Care",
    id: "eTRO.ZVFuhUxXw6HzTAjtfg3",
  },
  {
    name: "Anthony Ambulatory",
    id: "egxlDFt6t8n-cRqg1k0NObg3",
  },
  {
    name: "Arthur Optime",
    id: "ehq2i0YHiqAexdraJZgml1Q3",
  },
];
const ExploreFhirPatients = () => {
  const { data: ehrOptions } = useGetEHRSystemsQuery();

  const navigate = useNavigate();

  const [selectedEHR, setSelectedEHR] = useState(null);

  useEffect(() => {
    if (ehrOptions?.ehr_systems) {
      setSelectedEHR(ehrOptions?.ehr_systems?.filter((x) => x.ehr_id === 5));
    }
  }, [ehrOptions]);

  const handleViewData = (patient) => {
    navigate(`/explore-fhir/patients/${patient?.id}`, {
      state: {
        ehr: selectedEHR[0],
        patient: {
          patientId: patient?.id,
          patientName: patient?.name,
          patient: patient,
        },
      },
    });
  };

  const handleEHRProviderChange = (selectedOption) => {
    setSelectedEHR(selectedOption ? selectedOption : "");

    if (selectedOption) {
      // Refetch patients based on selected EHR
      // refetch();
    }
  };

  return (
    <div className="main-section-new">
      <div className="container main-content overview-content">
        <div className="row">
          <div className="col-12">
            <div className="same-heading-icon">
              <h4>Patient List</h4>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="mb-3 search-field">
                <Select
                  getOptionLabel={(option) => `${option.ehr_name}`}
                  getOptionValue={(option) => option.ehr_id}
                  options={
                    ehrOptions?.ehr_systems?.filter((x) => x.ehr_id == 5) || []
                  }
                  styles={colourStyles}
                  placeholder="Select EHR provider"
                  onChange={handleEHRProviderChange}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={selectedEHR}
                />
              </div>
            </div>
            <div className="white-box">
              <div className="table-container">
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Test Patient Name</th>
                      <th>FHIR ID</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedEHR &&
                      patients?.map((patient) => (
                        <tr key={patient.id}>
                          <td>{patient.name}</td>
                          <td>{patient.id}</td>
                          <td className="action-column">
                            <button
                              className="view-button"
                              onClick={() => handleViewData(patient)}
                              disabled={!selectedEHR}
                            >
                              View Data
                            </button>
                            {!selectedEHR && (
                              <span className="tooltip-text">
                                Please select an EHR provider first
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreFhirPatients;
