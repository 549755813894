import React, { useEffect, useRef, useState } from "react";
import CopyButton from "../../../components/elements/copyButton/CopyButton";
import Spinner from "../../../components/elements/Spinner/Spinner";
import { useLocation } from "react-router-dom";
import { Formik, Field } from "formik";
import Select from "react-select";
import {
  usePostDataFusionMutation,
  usePostOrgEhrConfigByIdMutation,
} from "../../../services/apiService";
import { toast } from "react-toastify";
import MasterUserLayout from "../../../components/MasterUser/MasterUserLayout";
import Breadcrumb from "../../../components/elements/Breadcrumb/Breadcrumb";
const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "0.4px solid #848484",
    borderRadius: "12px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "86px",
    width: "100%",
    color: "#323840",
    padding: "0.245rem 0.75rem",
    marginBottom: "18px",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "68px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const methodOptions = [
  { label: "GET", value: "GET" },
  { label: "POST", value: "POST" },
  { label: "PUT", value: "PUT" },
  { label: "DELETE", value: "DELETE" },
];

const MasterUserCustomerSubscription = () => {
  const location = useLocation();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const formRef = useRef(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [initialValues, setInitialValues] = useState({
    method: "",
    url: "",
    params: [{ key: "", value: "" }], // Ensure params array starts with one empty object
    headers: [{ key: "", value: "" }], // Ensure headers array starts with one empty object
    body: "",
  });

  const breadcrumbItems = [
    { label: "Customers", link: "/masteruser/customers" },
    { label: "Subscriptions" }, // No link for the last item as it's the current page
  ];

  const subscriptionId = location?.state?.subscriptionId;

  const [postOrgEhrConfigById, { isFetching, isLoading }] =
    usePostOrgEhrConfigByIdMutation();

  const [
    postDataFusion,
    { isFetching: isFusionDataFetching, isLoading: isFusionDataLoading },
  ] = usePostDataFusionMutation();

  useEffect(() => {
    if (subscriptionId) {
      postOrgEhrConfigById({ query: "", payload: { id: subscriptionId } }).then(
        (res) => {
          if (res?.data) {
            setCustomer(res?.data);
          }
        }
      );
    }
  }, [subscriptionId, postOrgEhrConfigById]);

  const [loadingScopes, setLoadingScopes] = useState({});
  const [individualLoading, setIndividualLoading] = useState(false);

  const handleRequestAll = () => {
    setLoading(true);

    // Initialize an object to keep track of loading states for each scope using ehr_scope_id
    const initialLoadingScopes = customer?.org_ehr_config?.scopes?.reduce(
      (acc, scope) => {
        acc[scope.ehr_scope_id] = true;
        return acc;
      },
      {}
    );

    setLoadingScopes(initialLoadingScopes);

    // Create an array of promises to track all requests
    const requests = customer?.org_ehr_config?.scopes?.map(
      (selectedRequest) => {
        const payload = {
          org_id: customer?.org_ehr_config?.org_id,
          scope_id: selectedRequest?.ehr_scope_id,
          org_name: customer?.org_ehr_config?.master_org?.org_name,
          client_name: customer?.org_ehr_config?.clinic_name,
          id: customer?.org_ehr_config?.secret_details?.[0]?.id,
          params: "",
        };

        return postDataFusion({ query: "", payload: payload })
          .then((res) => {})
          .finally(() => {
            setLoadingScopes((prev) => ({
              ...prev,
              [selectedRequest.ehr_scope_id]: false,
            }));
          });
      }
    );

    // Wait for all requests to complete
    Promise.all(requests)
      .then(() => {
        toast.success("Response successfully received!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectRequest = (el, index) => {
    setSelectedRequest(el);
    setInitialValues({
      method: el?.ehr_scope_type,
      url: `/api/data-fusion/${el?.ehr_request_url}`,
    });
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onSubmit = (values, { resetForm }) => {
    const formattedParams = Array.isArray(values.params)
      ? values.params.reduce((acc, param) => {
          if (param.key && param.value) {
            acc[param.key] = param.value;
          }
          return acc;
        }, {})
      : {}; // Default to empty object if params is undefined

    const formattedHeaders = Array.isArray(values.headers)
      ? values.headers.reduce((acc, header) => {
          if (header.key && header.value) {
            acc[header.key] = header.value;
          }
          return acc;
        }, {})
      : {}; // Default to empty object if headers is undefined

    const payload = {
      org_id: customer?.org_ehr_config?.org_id,
      scope_id: selectedRequest?.ehr_scope_id,
      org_name: customer?.org_ehr_config?.master_org?.org_name,
      client_name: customer?.org_ehr_config?.clinic_name,
      id: customer?.org_ehr_config?.secret_details?.[0]?.id,
      params: formattedParams,
      headers: formattedHeaders,
      body: values.body,
    };

    // console.log("Payload", payload);

    setIndividualLoading(true);
    setLoadingScopes((prev) => ({
      ...prev,
      [selectedRequest.ehr_scope_id]: true,
    }));

    postDataFusion({ query: "", payload: payload })
      .then((res) => {
        if (res?.data) {
          setApiResponse(res?.data?.response);
          toast.success(res?.data?.message);
        }
      })
      .finally(() => {
        setIndividualLoading(false);
        setLoadingScopes((prev) => ({
          ...prev,
          [selectedRequest.ehr_scope_id]: false,
        }));
      });

    resetForm();
  };

  return (
    <>
      <MasterUserLayout>
        <Breadcrumb items={breadcrumbItems} />
        <div className="health-system-sec">
          <div className="heading-system">
            <div className="heading-item">
              <i className="fa fa-building" aria-hidden="true" />
              <h4>{customer?.org_ehr_config?.master_org?.org_name}</h4>
            </div>
            <div className="heading-item">
              <button
                className={`common-same-button ${loading ? "loading" : ""}`}
                onClick={handleRequestAll}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span className="spinner"></span> Loading...
                  </>
                ) : (
                  "Request All"
                )}
              </button>
            </div>
            <div className="heading-item">
              <i className="fa fa-database" aria-hidden="true" />
              <h4>{customer?.org_ehr_config?.master_ehr_system?.ehr_name}</h4>
            </div>
          </div>
          <div className="health-describe">
            <div className="content-left">
              <div>
                <h5>DataFusion Request Endpoint</h5>
                <div className="d-flex align-items-center gap-1">
                  <CopyButton content="/api/data-fusion/" />
                </div>
              </div>
              <h5 className="blue-color">REQUEST</h5>
            </div>

            <div className="right-card">
              {customer &&
                customer?.org_ehr_config?.scopes?.map((el, index) => (
                  <div key={el?.ehr_scope_id}>
                    <div
                      className={"arrow-left new-bar "}
                      style={{
                        "--animation-color": "#3b8cd9",
                        "--progress-bar": "backward",
                      }}
                    >
                      <div></div>
                      <progress
                        id="file"
                        value="0"
                        max="100"
                        className="progress-bar"
                      >
                        {" "}
                        100%{" "}
                      </progress>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="d-flex align-items-center gap-3">
                          <p className="blue-text"></p>
                          <p className="ms-0">
                            {loadingScopes[el?.ehr_scope_id] && (
                              <span>Update in progress...</span>
                            )}
                          </p>
                        </div>
                        <p className="grey-text">{el?.ehr_scope_type}</p>
                      </div>
                    </div>
                    <div className="content-right">
                      <div>
                        <h5>{el?.resource + "-" + el?.ehr_scope_name}</h5>
                        <div className="d-flex align-items-center gap-1">
                          <CopyButton content={el?.ehr_request_url || ""} />
                        </div>
                      </div>
                      <button
                        className="blue-color"
                        onClick={() => handleSelectRequest(el, index)}
                        disabled={loadingScopes[el?.ehr_scope_id]}
                      >
                        {loadingScopes[el?.ehr_scope_id] ? (
                          <>
                            <span className="spinner"></span> Loading...
                          </>
                        ) : (
                          "Request"
                        )}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="subscribe-accordion" ref={formRef}>
          <div className="subscribe-accordion">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                onSubmit(values, resetForm);
                resetForm();
              }}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="postman-box">
                    <div className="first-line">
                      <Select
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={methodOptions}
                        styles={colourStyles}
                        placeholder=""
                        isRequired={true}
                        value={{
                          label: values.method,
                          value: values.method,
                        }}
                        label={"Select method type"}
                        onChange={(option) =>
                          setFieldValue("method", option.value)
                        }
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      <div className="w-100">
                        <Field
                          className={"did-floating-input"}
                          classNameLabel={
                            "did-floating-label did-floating-label-asterisk"
                          }
                          type="text"
                          name="url"
                          placeholder="Request Url"
                          disabled={true}
                        />
                      </div>
                      <div>
                        <button type="submit" className="common-same-button">
                          {individualLoading ? (
                            <>
                              <span className="spinner"></span> Loading...
                            </>
                          ) : (
                            "Send"
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="second-tab">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#home"
                          >
                            Query Params
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#menu1"
                          >
                            Headers
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#menu2"
                          >
                            Body
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content">
                        {/* Query Params Section */}
                        <div className="tab-pane container active" id="home">
                          <div>
                            {(values?.params || [{ key: "", value: "" }]).map(
                              (param, index) => (
                                <div key={index} className="first-line">
                                  <div className="w-100">
                                    <Field
                                      className={"did-floating-input"}
                                      type="text"
                                      name={`params.${index}.key`}
                                      placeholder="Key"
                                      onChange={handleChange}
                                      value={param.key || ""}
                                    />
                                  </div>
                                  <div className="w-100">
                                    <Field
                                      className={"did-floating-input"}
                                      type="text"
                                      name={`params.${index}.value`}
                                      placeholder="Value"
                                      onChange={handleChange}
                                      value={param.value || ""}
                                    />
                                  </div>
                                  <div>
                                    {values?.params?.length > 1 && (
                                      <button
                                        type="button"
                                        className="common-same-button"
                                        onClick={() => {
                                          setFieldValue(
                                            "params",
                                            values?.params?.filter(
                                              (_, i) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                            <div>
                              <button
                                type="button"
                                className="common-same-button"
                                onClick={() => {
                                  setFieldValue("params", [
                                    ...(values.params || []),
                                    { key: "", value: "" },
                                  ]);
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Headers Section */}
                        <div className="tab-pane container fade" id="menu1">
                          <div>
                            {(values?.headers || [{ key: "", value: "" }]).map(
                              (header, index) => (
                                <div key={index} className="first-line">
                                  <div className="w-100">
                                    <Field
                                      className={"did-floating-input"}
                                      type="text"
                                      name={`headers.${index}.key`}
                                      placeholder="Key"
                                      onChange={handleChange}
                                      value={header.key || ""}
                                    />
                                  </div>
                                  <div className="w-100">
                                    <Field
                                      className={"did-floating-input"}
                                      type="text"
                                      name={`headers.${index}.value`}
                                      placeholder="Value"
                                      onChange={handleChange}
                                      value={header.value || ""}
                                    />
                                  </div>
                                  <div>
                                    {values?.headers?.length > 1 && (
                                      <button
                                        type="button"
                                        className="common-same-button"
                                        onClick={() => {
                                          setFieldValue(
                                            "headers",
                                            values?.headers?.filter(
                                              (_, i) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                            <div>
                              <button
                                type="button"
                                className="common-same-button"
                                onClick={() => {
                                  setFieldValue("headers", [
                                    ...(values.headers || []),
                                    { key: "", value: "" },
                                  ]);
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Body Section */}
                        <div className="tab-pane container fade" id="menu2">
                          <div>
                            <Field
                              as="textarea"
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-label-asterisk"
                              }
                              name="body"
                              placeholder=" "
                              rows="4"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>

        <Spinner isLoading={isFetching || isLoading} />
      </MasterUserLayout>
    </>
  );
};

export default MasterUserCustomerSubscription;
