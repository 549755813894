import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  useGetEHRScopesQuery,
  usePostDataFusionExploreMutation,
} from "../../../services/apiService";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../../components/elements/Breadcrumb/Breadcrumb";
import ReactJson from 'react-json-view'

const colourStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    border: "1px solid var(--wp--preset--color--primary-blue)",
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
    minHeight: "auto",
    minWidth: "39px",
    width: "100%",
    color: "#323840",
    padding: "0.245rem 0.75rem",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px",
    minHeight: "30px",
    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const ExploreFhirPatientDetails = () => {
  const location = useLocation();
  const patientId = location?.state?.patient?.patientId;
  const patient = location?.state?.patient?.patient;
  const ehrId = location?.state?.ehr?.ehr_id;
  const [apiResponse, setApiResponse] = useState(null);
  const [patientDetails, setPatientDetails] = useState({
    gender: "N/A",
    birthDate: "N/A",
    fhirId: patientId || "N/A",
  });

  const { data: scopeOptions, isLoading: isScopeLoading } =
    useGetEHRScopesQuery(ehrId, {
      skip: !ehrId,
    });

  const [selectedScope, setSelectedScope] = useState(null);
  const [
    postDataFusionExplore,
    { data: exploreData, isLoading: isSubmitting },
  ] = usePostDataFusionExploreMutation();

  const extractPatientDetails = (data) => {
    if (!data || !data.response) return;

    const patientResource = data.response;
    console.log("patientResource", patientResource);

    setPatientDetails((prevDetails) => ({
      gender: patientResource?.gender || prevDetails.gender,
      birthDate: patientResource?.birthDate || prevDetails.birthDate,
      fhirId: patientResource?.id || prevDetails.fhirId,
    }));
  };

  // Fetch data when scope is selected
  const fetchData = (scope) => {
    if (scope) {
      postDataFusionExplore({
        query: "",
        payload: {
          scope_id: scope.ehr_scope_id,
          params: scope?.ehr_scope_name === 'Appointment' ? `?date={date}&identifier={identifier}&patient=${patientId}&status={status}&service-category={service-category}`
            : scope?.ehr_scope_name === 'Condition' ? `?patient=${patientId}&category=encounter-diagnosis`
              : scope?.ehr_scope_name === 'AllergyIntolerance' ? `?clinical-status=active&patient=${patientId}`
                : scope?.ehr_scope_name === 'Medication' ? `/e52MAJSdNhwg6s0Rab0h.Cw3`
                  : scope?.ehr_scope_name === 'Binary' ? `/eibaYRQF6yVTF.5R2n92hhMhIzS.lJx9doPV5HgjIawc3`
                    : `/${patientId}`,
        },
      }).then((res) => {
        if (res?.data) {
          setApiResponse(res.data);
          extractPatientDetails(res.data);
        }
      });
    }
  };

  const handleExport = () => {
    if (!apiResponse) return;
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const jsonData = JSON.stringify(apiResponse, null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `FHIR_${location?.state?.ehr?.ehr_name}_Patient_${patientId}_${timestamp}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Automatically select the first scope when data is available
  useEffect(() => {
    if (scopeOptions?.ehr_scopes?.length > 0) {
      const firstScope = scopeOptions.ehr_scopes[0];
      setSelectedScope(firstScope);
      fetchData(firstScope);
    }
  }, [scopeOptions]);

  const handleScopeChange = (selectedOption) => {
    setSelectedScope(selectedOption);
    fetchData(selectedOption);
  };

  return (
    <div className="main-section-new">
      <div className="container main-content">
        <nav aria-label="breadcrumb">
          <Breadcrumb
            items={[
              { label: "Patients", link: "/explore-fhir/patients" },
              { label: location?.state?.patient?.patientName },
            ]}
          />
        </nav>

        <div className="container mt-5">
          <div className="same-heading-icon">
            <h4>Test Patient List</h4>
          </div>
          <div className="card dash-shadow-box mb-3 border">
            <div className="card-body">
              <div className="row align-items-center">
                {/* Left Column */}
                <div className="col-md-5"  >
                  <h1 className="card-title text-start">
                    {location?.state?.patient?.patientName}
                  </h1>
                  <p className="card-text">
                    <strong>FHIR Server:</strong> {location?.state?.ehr?.ehr_name}
                  </p>
                </div>
                <div className="col-md-1">
                  <hr className=" border-dashed" />
                </div>


                {/* Right Column */}
                <div className="col-md-6">
                  <p className="card-text">
                    <strong>Gender:</strong> {patientDetails.gender}
                  </p>
                  <p className="card-text">
                    <strong>Birth Date:</strong> {patientDetails.birthDate}
                  </p>
                  <p className="card-text">
                    <strong>FHIR ID:</strong> {patientDetails.fhirId}
                  </p>
                  {patient?.MRN && (
                    <p className="card-text">
                      <strong>MRN ID:</strong> {patient?.MRN}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>



          <div className="mb-3">
            <h3>Select FHIR Resource</h3>
            <div className="col-lg-12 col-md-12">
              <div className="mb-3">
                <Select
                  getOptionLabel={(option) =>
                    `${option.resource} - ${option.ehr_scope_name}`
                  }
                  getOptionValue={(option) => option.ehr_scope_id}
                  options={scopeOptions?.ehr_scopes || []}
                  styles={colourStyles}
                  placeholder="Select FHIR Scope"
                  onChange={handleScopeChange}
                  value={selectedScope}
                  isLoading={isScopeLoading}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            {/* <h3>Query Results</h3>
          <div className="card mt-3 bg-black white-text">
            <small id="query-url-display">
              FHIR Query URL:
              https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4/Patient?_id=
              {patientId}
            </small>
          </div> */}

            <div className="table-responsive mt-3">
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={!apiResponse ? "Please select a scope first" : ""}
              >
                <button
                  className="common-same-button "
                  onClick={handleExport}
                  disabled={!apiResponse}
                >
                  Export Results
                </button>
              </div>
            </div>

            {/* Show loading message when API is fetching data */}
            {isSubmitting && <p>Loading data...</p>}

            {/* Show API response */}
            {exploreData && typeof exploreData === 'object' && (
              <div className="card mt-3">
                <div className="card-body">
                  <ReactJson src={exploreData} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreFhirPatientDetails;
