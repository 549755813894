import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import {
  REACT_APP_BACKEND_URL,
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../config/config";
import CryptoJS from "crypto-js";

const prepareHeaders = (headers, { getState }) => {
  const encryptedToken = localStorage.getItem("accessToken");

  const token =
    encryptedToken &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);

  headers.set(
    "x-api-key",
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  );

  if (token) {
    headers.set("authorization", `${token}`);
  }

  return headers;
};

const rawBaseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_BACKEND_URL,
  prepareHeaders,
  // responseHandler: (response) => response,
});

const retriedBaseQuery = retry(
  async (args, api, extraOptions) => {
    const result = await rawBaseQuery(args, api, extraOptions);

    // Customize retry logic as needed
    if (result.error && result.error.status !== 504) {
      retry.fail(result.error);
    }

    return result;
  },
  { maxRetries: 2 } // Set max retries here
);

export const serverAPI = createApi({
  reducerPath: "serverAPI",
  baseQuery: retriedBaseQuery,
  endpoints: (builder) => ({
    getCredentials: builder.query({
      query: () => ({ url: "https://geolocation-db.com/json/" }),
    }),

    postUserLogin: builder.mutation({
      query: ({ query, payload }) => {
        return {
          url: "/auth/login",
          method: "POST",
          body: payload,
        };
      },
    }),
    postForgotPassword: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: payload,
      }),
    }),
    postResetPassword: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: payload,
      }),
    }),
    postOtpRequest: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/auth/otp-request",
        method: "POST",
        body: payload,
      }),
    }),
    postVerifyOtp: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/auth/verify-otp",
        method: "POST",
        body: payload,
      }),
    }),
    getDashboardStats: builder.query({
      query: () => ({ url: "/get-dashboard-stats" }),
    }),
  }),
});

export const {
  useGetCredentialsQuery,
  usePostUserLoginMutation,
  usePostForgotPasswordMutation,
  usePostResetPasswordMutation,
  usePostOtpRequestMutation,
  usePostVerifyOtpMutation,
  useGetDashboardStatsQuery,
} = serverAPI;

export const userAPI = createApi({
  reducerPath: "userAPI",
  baseQuery: retriedBaseQuery,
  endpoints: (builder) => ({
    postUserActivate: builder.mutation({
      query: () => ({
        url: "/user/activate",
        method: "POST",
      }),
    }),
    postUserChangePassword: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/user/change-password",
        method: "POST",
        body: payload,
      }),
    }),
    getSingleUserDetail: builder.query({
      query: () => ({ url: "/user/get-user" }),
    }),
    postAddUser: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/user/add-user",
        method: "POST",
        body: payload,
      }),
    }),
    postAllUsersData: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/user/all-users",
        method: "POST",
        body: payload,
      }),
    }),
    putUserUpdate: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/user/update-user",
        method: "PUT",
        body: payload,
      }),
    }),
    putUserUpdateProfile: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/user/update-profile",
        method: "PUT",
        body: payload,
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/user/delete-user",
        method: "DELETE",
        body: payload,
      }),
    }),
  }),
});

export const {
  usePostUserChangePasswordMutation,
  usePostUserActivateMutation,
  useGetSingleUserDetailQuery,
  usePostAddUserMutation,
  usePostAllUsersDataMutation,
  usePutUserUpdateMutation,
  useDeleteUserMutation,
  usePutUserUpdateProfileMutation,
} = userAPI;

export const organizationAPI = createApi({
  reducerPath: "organizationAPI",
  baseQuery: retriedBaseQuery,
  endpoints: (builder) => ({
    getCloudStorageDetails: builder.query({
      query: () => ({ url: "/master-organization/cloud-storage" }),
    }),
    postCloudDetails: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/master-organization/create-update-cloud-storage",
        method: "POST",
        body: payload,
      }),
    }),
    postUserRegister: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/master-organization/register",
        method: "POST",
        body: payload,
      }),
    }),
    putProfileUpdate: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/master-organization/update-profile",
        method: "PUT",
        body: payload,
      }),
    }),
    getEHRSystems: builder.query({
      query: () => ({ url: "/get-ehr-systems" }),
    }),
    getEHRScopes: builder.query({
      query: (ehr_id) => ({ url: `/get-ehr-scopes/${ehr_id}` }),
    }),
    getCloudProviders: builder.query({
      query: () => ({ url: "/get-cloud-storage-providers" }),
    }),
    getEmailProviders: builder.query({
      query: () => ({ url: "/get-email-providers" }),
    }),
    getSmsProviders: builder.query({
      query: () => ({ url: "/get-sms-providers" }),
    }),
    postDataFusionExplore: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/data-fusion-explore",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  usePostUserRegisterMutation,
  usePutProfileUpdateMutation,
  useGetCloudStorageDetailsQuery,
  useGetEHRSystemsQuery,
  useGetEHRScopesQuery,
  usePostCloudDetailsMutation,
  useGetCloudProvidersQuery,
  useGetEmailProvidersQuery,
  useGetSmsProvidersQuery,
  usePostDataFusionExploreMutation,
} = organizationAPI;

export const adminAPI = createApi({
  reducerPath: "adminAPI",
  baseQuery: retriedBaseQuery,
  endpoints: (builder) => ({
    postAllUsersDataForAdmin: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/admin/users",
        method: "POST",
        body: payload,
      }),
    }),
    postAllOrganizationsDataForAdmin: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/admin/organizations",
        method: "POST",
        body: payload,
      }),
    }),
    postAddOrganizationsDataForAdmin: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/admin/add-organization",
        method: "POST",
        body: payload,
      }),
    }),
    deleteOrganization: builder.mutation({
      query: (org_id) => ({
        url: `/admin/delete-org/${org_id}`,
        method: "DELETE",
      }),
    }),
    postAddUserByAdmin: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/admin/add-user",
        method: "POST",
        body: payload,
      }),
    }),
    putUserUpdateByAdmin: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/admin/update-user",
        method: "PUT",
        body: payload,
      }),
    }),
    postAddEHRByAdmin: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/admin/add-ehr-system",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  usePostAllUsersDataForAdminMutation,
  usePostAllOrganizationsDataForAdminMutation,
  useDeleteOrganizationMutation,
  usePostAddOrganizationsDataForAdminMutation,
  usePostAddUserByAdminMutation,
  usePutUserUpdateByAdminMutation,
  usePostAddEHRByAdminMutation,
} = adminAPI;

export const customerAPI = createApi({
  reducerPath: "customerAPI",
  baseQuery: retriedBaseQuery,
  endpoints: (builder) => ({
    postAddCustomer: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/create-org-ehr-config",
        method: "POST",
        body: payload,
      }),
    }),

    putCustomerUpdate: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/update-org-ehr-config",
        method: "PUT",
        body: payload,
      }),
    }),

    deleteCustomer: builder.mutation({
      query: ({ query, payload }) => ({
        url: `delete-org-ehr-config/${query}`,
        method: "DELETE",
      }),
    }),
    postAllCustomersDataForAdmin: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/get-org-ehr-config",
        method: "POST",
        body: payload,
      }),
    }),
    postOrgEhrConfigById: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/get-org-ehr-config-by-id",
        method: "POST",
        body: payload,
      }),
    }),
    postDataFusion: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/data-fusion",
        method: "POST",
        body: payload,
      }),
    }),
    postTestClientConnection: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/test-client-connection",
        method: "POST",
        body: payload,
      }),
    }),
    postTestCloudConnection: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/test-cloud-storage",
        method: "POST",
        body: payload,
      }),
    }),
    postEmailSmsDetails: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/add-update-sms-email-settings",
        method: "POST",
        body: payload,
      }),
    }),
    getEmailSmsSettings: builder.mutation({
      query: (org_id) => ({ url: `/get-sms-email-settings/${org_id}` }),
    }),
    postNotificationDetails: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/update-user-notification-settings",
        method: "PUT",
        body: payload,
      }),
    }),
    getNotificationSettings: builder.mutation({
      query: (id) => ({ url: `/get-user-notification-settings/${id}` }),
    }),
    postAllNotificationData: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/get-notifications`,
        method: "POST",
        body: payload,
      }),
    }),
    postSentNotificationData: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/get-sent-notifications`,
        method: "POST",
        body: payload,
      }),
    }),
    readNotification: builder.mutation({
      query: (notificationId) => ({
        url: `/read-notification/${notificationId}`,
        method: "PUT",
      }),
    }),
    getMasterUserNotifications: builder.mutation({
      query: (id) => ({ url: `/get-sent-notifications/${id}` }),
    }),
    postAddNotification: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/send-notification`,
        method: "POST",
        body: payload,
      }),
    }),
    postAddSchedule: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/add-scheduled-jobs",
        method: "POST",
        body: payload,
      }),
    }),
    putUpdateSchedule: builder.mutation({
      query: ({ query, payload }) => ({
        url: "/update-scheduled-jobs",
        method: "PUT",
        body: payload,
      }),
    }),
    postAllScheduledJobsData: builder.mutation({
      query: ({ query, payload }) => ({
        url: `/get-scheduled-jobs`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  usePostAddCustomerMutation,
  usePutCustomerUpdateMutation,
  useDeleteCustomerMutation,
  usePostAllCustomersDataForAdminMutation,
  useGetEHRConfigByIdQuery,
  usePostOrgEhrConfigByIdMutation,
  usePostDataFusionMutation,
  usePostTestClientConnectionMutation,
  usePostTestCloudConnectionMutation,
  usePostEmailSmsDetailsMutation,
  useGetEmailSmsSettingsMutation,
  usePostNotificationDetailsMutation,
  useGetNotificationSettingsMutation,
  usePostAllNotificationDataMutation,
  usePostSentNotificationDataMutation,
  useReadNotificationMutation,
  useGetMasterUserNotificationsQuery,
  usePostAddNotificationMutation,
  usePostAddScheduleMutation,
  usePutUpdateScheduleMutation,
  usePostAllScheduledJobsDataMutation,
} = customerAPI;
